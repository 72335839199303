export const siteData = {
  "name": "COFINARI",
  "address": "12 Rue des Tilleuls,",
  "postCode": "17430",
  "city": "tonnay",
  "phone": "0593009112",
  "url": "https://evaralda.com",
  "urlSub": "evaralda.com",
  "price": "price_1Q0Se009PLqSIjRa1boiyULt",
  "pk": "pk_live_51Q0Rth09PLqSIjRaUQinjzeHGv0sRTpPP14eNvWcZGQZIP8KMgsm9732Pe34863YyvAUZQRrui4ptB2orikY1xgP00g4P1KUIy",
  "sk": "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"
};